<template>
  <div>
    <ib-single-accordion
      :value="expanded"
      :title="infoTexts.title"
      :subtitle="infoTexts.subtitle"
      @input="setBusinessPlanAdditionalInfoExpanded({ key: sectionName, value: $event })"
    >
      <div class="position-relative">
        <ai-buddy-button-rounded
          v-if="showAiBuddy && canEdit"
          class="ai-buddy d-none d-md-block"
          :class="{'filter-grayscale': disabled}"
          @click="$emit('ai-buddy-open')"
        />

        <ib-loader-overlay v-if="loading" />
        <div v-if="disabled" class="overlay" @click="onOverlayClick" />

        <jodit-editor
          ref="joditEditor"
          v-model="content"
          :config="joditEditorConfig"
          :buttons="joditEditorButtons"
        />
      </div>
      <div class="d-flex justify-content-between">
        <p class="note-status">
          {{ contentLength }} / {{ charactersLimit }}
        </p>
        <p class="note-status">
          {{ noteStatus }}
        </p>
      </div>
    </ib-single-accordion>

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->
  </div>
</template>

<script>
import AiBuddyButtonRounded from '@/views/Home/StoryMode/Components/AiBuddyButtonRounded.vue'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted.vue'
import EventBus from '@/event-bus'
import { Jodit } from 'jodit'
import { JoditEditor } from 'jodit-vue'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import MixinUnsupportedPlugins from '@/mixins/unsupportedPlugins'

import _debounce from 'lodash/debounce'
import { validateImage } from '@/helpers/imageHelper'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import IbLoaderOverlay from '@/components/_v2/IbLoaderOverlay.vue'

export default {
  name: 'BusinessPlanAdditionalInfo',

  components: {
    AiBuddyButtonRounded,
    DialogProcessCompleted,
    IbLoaderOverlay,
    JoditEditor
  },

  mixins: [MixinIdeaRoles, MixinUnsupportedPlugins, MixinProcessCompleted],

  props: {
    sectionName: {
      type: String,
      required: true
    },
    completedStepName: {
      type: String,
      required: true
    },
    showAiBuddy: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: false,
      firstLoad: true,
      noteStatus: '',
      content: ''
    }
  },

  computed: {
    ...mapGetters('idea', ['getBusinessPlanSectionByName']),
    ...mapGetters('theme', ['getBusinessPlanAdditionalInfoExpand']),

    section () {
      return this.getBusinessPlanSectionByName(this.sectionName)
    },

    expanded () {
      return this.getBusinessPlanAdditionalInfoExpand(this.sectionName)
    },

    infoTexts () {
      return this.$t(`businessPlanAdditionalInfoTexts.${this.sectionName.toLowerCase()}`)
    },

    disabled () {
      return this.$store.state.idea.storyMode.common.completedSteps[this.completedStepName]
    },

    joditEditorConfig () {
      return {
        // height: 200,
        minHeight: 400,
        // disabled: !this.canEdit || this.disabled,
        toolbarButtonSize: 'middle',
        inline: false,
        showPlaceholder: true,
        placeholder: this.canEdit ? this.$t('addMoreDetails.addNoteText') : '',
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        limitChars: 10000,
        askBeforePasteHTML: false,
        defaultActionOnPaste: 'insert_clear_html',
        controls: {
          paragraph: {
            list: Jodit.atom({
              h2: 'Heading 1',
              h3: 'Heading 2',
              p: 'Paragraph',
              blockquote: 'Quote'
            })
          }
        },
        disablePlugins: ['addNewLine']
      }
    },

    joditEditorButtons () {
      return [
        'bold',
        'italic',
        'underline',
        // 'strikethrough',
        // 'eraser',
        'paragraph',
        // 'superscript',
        // 'subscript',
        'hr',
        'ul',
        'ol',
        'brush',
        'link',
        'table',
        {
          name: 'Insert image',
          tooltip: 'Insert picture',
          iconURL: require('@/assets/img/icons/jodit-image.svg'),
          exec: this.joditImageButtonHandler
        }
      ]
    },

    contentLength () {
      return this.content.replace(/(<([^>]+)>)/ig, '').length
    },

    charactersLimit () {
      return 10000
    }
  },

  watch: {
    content: _debounce(function () {
      if (this.firstLoad) {
        this.firstLoad = false
        this.save()

        return
      }
      this.save()
    }, 500)
  },

  mounted () {
    this.content = this.section.subTitle

    EventBus.$on('ai-help-me-write-save', (payload) => {
      this.content = payload
    })
  },

  destroyed () {
    EventBus.$off('ai-help-me-write-save')
  },

  methods: {
    ...mapActions('idea', ['uploadImage', 'updateBusinessPlanAdditionalInfo']),
    ...mapMutations('theme', ['setBusinessPlanAdditionalInfoExpanded']),

    save () {
      if (!this.canEdit) return
      if (this.isUnsupportedPluginOn) {
        this.showUnsupportedPluginNotification(this.content)

        return
      }
      this.noteStatus = ''

      const jodit = this.$refs.joditEditor.editor

      if (jodit.getEditorValue().replace(/(<([^>]+)>)/ig, '').length > 10000) {
        this.$notify.closeAll()
        this.$notify({ title: this.$t('error'), message: this.$t('validationMessages.maximumCharacter', { max: this.charactersLimit }), type: 'error' })
        this.content = this.content.substring(0, 10000)
      } else {
        this.noteStatus = this.$t('status.saving')
        this.updateBusinessPlanAdditionalInfo({ id: this.section.id, content: this.content })
          .then(_ => {
            this.noteStatus = this.$t('status.saved')
          })
          .catch(_ => {
            this.noteStatus = this.$t('error')
          })
      }
    },

    joditImageButtonHandler () {
      if (!this.canEdit) return

      const jodit = this.$refs.joditEditor.editor

      if (jodit.getEditorValue().replace(/(<([^>]+)>)/ig, '').length > this.charactersLimit) {
        alert('You have reached the maximum content length, you can not upload any more images!')
      } else {
        // Add a file input element to the document, then click it (open file).
        const input = document.createElement('input')
        input.hidden = true
        input.setAttribute('type', 'file')
        input.click()
        document.body.appendChild(input)
        // Once file is selected.
        input.onchange = () => {
          const file = input.files[0]
          const images = jodit.currentPlace.editor.getElementsByTagName('img').length
          if (!validateImage(file, images)) return
          // Create form.
          const formData = new FormData()
          formData.append('image', file)

          this.loading = true
          this.uploadImage({ formData, type: 'business-plan' })
            .then(response => {
              jodit.selection.insertImage(response.url)
              this.loading = false
              this.firstLoad = false
            })
            .catch(_ => {
              this.loading = false
            })
        }
      }
    },

    onOverlayClick () {
      if (!this.canEdit) return
      this.openedDialogProcessCompleted()
    }
  }
}
</script>

<style scoped lang="scss">
.note-status {
  text-align: right;
  font-size: small;
  height: 18px;
  color: $color-text-light-gray;
}

h2 {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.text-lead {
  opacity: .5;
}

.overlay {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .7;
  background: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.filter-grayscale {
  filter: grayscale(1);
}

.ai-buddy {
  font-size: 24px;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 20px;
}
</style>
